import React from 'react';
import './AboutUs.css';

const AboutPage = () => {
  return (
    <div>
      <h1>Welcome to Quick But Healthy!</h1>
      <p>About US.</p>
    </div>
  );
};

export default AboutPage;
